import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="alert alert-info">
    <span className="material-icons">get_app</span> Login to <strong><a href="https://themestore.papathemes.com/bigcommerce" target="_blank">PapaThemes Store</a></strong> to download all these child themes for free when you've already purchased Supermarket theme.
    </div>
    <div className="alert alert-warning">
    <span className="material-icons">warning</span> This free child theme has limited techical support. We'll try to answer your questions about this theme, but we're not committed to answer all and fix all bugs of this theme.
    </div>
    <h1 {...{
      "id": "sima-headphones-theme"
    }}>{`Sima Headphones Theme`}</h1>
    <h2 {...{
      "id": "sections-manager"
    }}>{`Sections Manager`}</h2>
    <p>{`You can select the sections displayed on the homepage (maximum 11 sections) in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Sections`}</strong>{`.`}</p>
    <p><img alt="Homepage Sections Manager" src={require("../img/homepage-headphones-sections-manager.png")} /></p>
    <hr></hr>
    <p><strong parentName="p">{`List of available section keys:`}</strong></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`headphones_topbanner`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`popular_products`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`sale_off`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`banner_sale_headphones`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`products_by_category_sorting_tabs`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`reviews_slider`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`blog_recent`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`support_info`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`form_social`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "headphones-top-banner-section"
    }}>{`Headphones Top Banner Section`}</h2>
    <p><img alt="Headphones Top Banner Section" src={require("../img/sections/headphones-top-banner-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Headphones Top Banner`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/headphones-top-banner-section-code.png")} /></p>
    <h2 {...{
      "id": "new--featured--bestselling-products"
    }}>{`New / Featured / Bestselling Products`}</h2>
    <h3 {...{
      "id": "default-style"
    }}>{`Default style:`}</h3>
    <p><img alt="Headphones Top Banner Section" src={require("../img/sections/headphones-popular-products-section.png")} /></p>
    <p>{`Configure New Products, Featured Products, Bestselling Products showing on the home page in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`Number of products to display.`}</li>
      <li parentName="ul">{`Heading of the new, featured, bestselling product blocks.`}</li>
      <li parentName="ul">{`Sub-heading.`}</li>
      <li parentName="ul">{`Columns (number of products per row).`}</li>
      <li parentName="ul">{`Columns on tablet.`}</li>
      <li parentName="ul">{`Columns on mobile.`}</li>
      <li parentName="ul">{`Type (Grid or List).`}</li>
    </ul>
    <h2 {...{
      "id": "headphones-banner-parallax"
    }}>{`Headphones Banner Parallax`}</h2>
    <p><img alt="Headphones Banner Parallax Section" src={require("../img/sections/headphones-banner-parallax-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Headphones Banner Parallax`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/headphones-banner-parallax-section-code.png")} /></p>
    <h2 {...{
      "id": "headphones-product-sale-off-section"
    }}>{`Headphones Product Sale Off Section`}</h2>
    <p><img alt="Headphones Product Sale Off Section" src={require("../img/sections/headphones-product-sale-off-section.png")} /></p>
    <p>{`Configure New Products Sale Off showing on the home page in `}<strong parentName="p">{`Manage`}</strong>{` > `}<strong parentName="p">{`Products`}</strong>{`:`}</p>
    <p><img alt="banner content html source editor" src={require("../img/headphones-product-sale-off-section-code.png")} /></p>
    <h2 {...{
      "id": "headphones-banner-sale-section"
    }}>{`Headphones Banner Sale Section`}</h2>
    <p><img alt="Headphones Banner Sale Section" src={require("../img/sections/headphones-banner-sale-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Headphones Banner Sale`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/headphones-banner-sale-section-code.png")} /></p>
    <h2 {...{
      "id": "headphones-products-by-category-sorting-tabs-section"
    }}>{`Headphones Products By Category Sorting Tabs Section`}</h2>
    <p><img alt="Headphones Banner Sale Section" src={require("../img/sections/headphones-products-by-category-sorting-tabs-section.png")} /></p>
    <p>{`This section will show products organized categories. You can choose number of categories to display products. Categories are sorted by the order specified in `}<strong parentName="p">{`Product Categories`}</strong>{` in admin manager.`}</p>
    <p>{`To configure this section, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > `}<strong parentName="p">{`Products by Category with Sorting Tabs`}</strong>{`:`}</p>
    <p><img alt="banner content html source editor" src={require("../img/banner-products-by-category-sorting-tabs-source-editor.png")} /></p>
    <h3 {...{
      "id": "optimize-for-execution-time"
    }}>{`OPTIMIZE FOR EXECUTION TIME`}</h3>
    <p>{`By default, all products will be loaded and displayed when opening the page. At a result, it takes a longer execution time that can affect your site's Page Speed score. To avoid this, you can select `}<strong parentName="p">{`Only load when scrolling to the viewport`}</strong>{` option, the products won't loaded until user scrolls down to this area.`}</p>
    <h3 {...{
      "id": "specify-which-categories-to-display"
    }}>{`SPECIFY WHICH CATEGORIES TO DISPLAY`}</h3>
    <p>{`By default, the product categories displayed are the top categories in the order in which they are sorted on the main navigation. You can limit number of categories to display by setting Number of Categories option.`}</p>
    <p>{`You can manually specify which categories should be displayed by setting the categories ID to Category ID box, separated by comma. For example: 200,221,254,275. Check instruction How to find the category ID. Note that Number of Categories will be ignored if you choose to display categories manually.`}</p>
    <h2 {...{
      "id": "headphones-reviews-slider-section"
    }}>{`Headphones Reviews Slider Section`}</h2>
    <p><img alt="Headphones Reviews Slider Section" src={require("../img/sections/headphones-reviews-slider-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Headphones Reviews Slider`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/headphones-reviews-slider-section-code.png")} /></p>
    <h2 {...{
      "id": "headphones-recent-posts-section"
    }}>{`Headphones Recent Posts Section`}</h2>
    <p><img alt="Headphones Recent Posts Section" src={require("../img/sections/headphones-recent-posts-section.png")} /></p>
    <p>{`To show Recent Posts section on `}<strong parentName="p">{`Home Page`}</strong>{`, go to `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Homepage`}</strong>{` > set `}<strong parentName="p">{`Recent blog posts`}</strong>{` = number of posts to show up.`}</p>
    <h2 {...{
      "id": "headphones-support-info-section"
    }}>{`Headphones Support Info Section`}</h2>
    <p><img alt="Headphones Support Info Section" src={require("../img/sections/furniture-support-info-section.png")} /></p>
    <p>{`To edit content and images in this block, open the builder tool: `}<a parentName="p" {...{
        "href": "https://bc-furnisima-builder.papathemes.com/"
      }}>{`https://bc-furnisima-builder.papathemes.com/`}</a></p>
    <p>{`Chooes `}<strong parentName="p">{`Furniture Live Help`}</strong>{` tab, fill in the form and follow the instruction on that page to create banner.`}</p>
    <p><img alt="banner content html source editor" src={require("../img/furniture-support-info-section-code.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      